import { AppLogic } from '../src/components/AppLogic/AppLogic';
import { GlobalLayout } from '../src/components/Layouts/GlobalLayout';
import { META_HOMEPAGE_TITLE } from '../src/constants/constants';
import { SSRResult } from '../src/interfaces/nextjs';
import { Homepage } from '../src/views/Homepage/Homepage';

export async function getServerSideProps(): SSRResult {
  return {
    props: {
      _page: 'langing_page',
      _pageTitle: META_HOMEPAGE_TITLE,
    },
  };
}

Homepage.getLayout = function getLayout(page: any) {
  return (
    <AppLogic pageProps={page.props}>
      <GlobalLayout>{page}</GlobalLayout>
    </AppLogic>
  );
};

export default Homepage;

import React from 'react';

import Image from 'next/image';

import {
  MILLION_CATCHES,
  THOUSAND_GEAR_REVIEWS,
  MILLION_PERSONAL_BESTS,
} from '../../constants/constants';
import { Heading } from '../Heading/Heading';
import { Text } from '../Text/Text';

import bragIconBests from './assets/bragIconBests.svg';
import bragIconFish from './assets/bragIconFish.svg';
import bragIconGear from './assets/bragIconGear.svg';

export const BragBarList = () => (
  <div
    className="w-col-4 sm:w-col-10 md:w-col-6 my-md mx-auto flex flex-col justify-between sm:flex-row"
    data-testid="bragBar"
  >
    <div className="mb-4 flex items-center sm:mb-0 sm:flex-col">
      <Image alt="fish icon" width={68} height={51} className="mb-2" src={bragIconFish} />
      <div className="ml-4 flex flex-col items-start sm:ml-0 sm:items-center">
        <Heading className="mb-0 sm:mb-2">{`${MILLION_CATCHES}M`}</Heading>
        <Text variant="sm" className="font-semibold">
          total catches logged
        </Text>
      </div>
    </div>
    <div className="mb-4 flex items-center sm:mb-0 sm:flex-col">
      <Image alt="gear icon" width={68} height={51} className="mb-2" src={bragIconGear} />
      <div className="ml-4 flex flex-col items-start sm:ml-0 sm:items-center">
        <Heading className="mb-0 sm:mb-2">{`${THOUSAND_GEAR_REVIEWS}k`}</Heading>
        <Text variant="sm" className="font-semibold">
          gear reviews
        </Text>
      </div>
    </div>
    <div className="flex items-center sm:flex-col">
      <Image
        alt="personal bests icon"
        width={68}
        height={51}
        className="mb-2"
        src={bragIconBests}
      />
      <div className="ml-4 flex flex-col items-start sm:ml-0 sm:items-center">
        <Heading className="mb-0 sm:mb-2">{`${MILLION_PERSONAL_BESTS}M`}</Heading>
        <Text variant="sm" className="font-semibold">
          new personal bests
        </Text>
      </div>
    </div>
  </div>
);

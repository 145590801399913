import { forwardRef } from 'react';

import { ImageProps, getImageProps } from 'next/image';

type SourceProps = Omit<React.JSX.IntrinsicElements['source'], 'srcSet' | 'src'> &
  Pick<ImageProps, 'src' | 'loader' | 'unoptimized' | 'quality'>;

export const Source = forwardRef<HTMLSourceElement, SourceProps>(function Source(
  { src, loader, unoptimized, quality, sizes, ...rest },
  ref,
) {
  const {
    props: { srcSet },
  } = getImageProps({ src, loader, unoptimized, quality, sizes, fill: true, alt: '' });

  return <source {...rest} srcSet={srcSet} sizes={sizes} ref={ref} />;
});
